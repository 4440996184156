import { $, FallbackLocaleInputType } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const GetFilterEventsQuery = typedGql("query")({
  GetFilterEvents: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      limit: $("limit", "Int"),
      where: $("where", "Event_where"),
      sort: $("sort", "String"),
      page: $("page", "Int"),
    },
    {
      totalDocs: true,
      totalPages: true,
      page: true,
      docs: {
        id: true,
        name: true,
        status: true,
        permalink: true,
        level: true,
        thumbnail: [
          {},
          {
            url: true,
          },
        ],
        criteria: {
          audiences: [{}, { id: true, name: true, value: true }],
          categories: [{}, { id: true, name: true, value: true }],
          // tags: [{}, { id: true, name: true, value: true }],
          specialTags: [{}, { id: true, name: true, value: true }],
        },
        sections: {
          startDatetime: true,
          endDatetime: true,
          fullDay: true,
          toThisDay: true,
          location: [
            {},
            {
              name: true,
              district: [
                {},
                {
                  id: true,
                  name: true,
                  value: true,
                },
              ],
            },
          ],
          address: true,
          coordinate: true,
          district: [
            {},
            {
              id: true,
              name: true,
              value: true,
            },
          ],
          recurrance: { type: true, weekday: true },
        },
        fees: {
          fee: true,
          remark: true,
        },
      },
    },
  ],
});

export default GetFilterEventsQuery;
