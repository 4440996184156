import { Box } from "@chakra-ui/react";

const AppBarWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{ position: "sticky", top: 0, zIndex: 99, bg: "#FFF" }}
      px={[1, 1, 0]}
      borderBottom={"1px solid"}
      borderColor={"brand.light-grey"}
      display={{ base: "flex", lg: "none" }}
    >
      {children}
    </Box>
  );
};

export default AppBarWrapper;
